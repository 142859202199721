export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'image', label: "Banner", _style:'min-width:100px' },
    { key: 'name', label: "Kategori", _style:'min-width:100px' },
    { key: 'event_name', label: "Event", _style:'min-width:100px' },
    { key: 'available_slot', label: "Ketersediaan Slot", _style:'min-width:100px' },
    { key: 'max_quota', label: "Maksimal Kuota", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
